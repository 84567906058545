#root {
  .color-spot {
    background-color: var(--frno-blue-800);
  }

  .color-fixed {
    background-color: var(--frno-blue-300);
  }

  .color-plus {
    background-color: var(--frno-yellow-200);
  }

  .color-other {
    background-color: var(--frno-cloud-300);
  }

  .checkbox-spot {
    color: var(--frno-blue-800);

    &:hover {
      border-color: var(--frno-blue-800);
    }

    &:checked {
      border-color: var(--frno-blue-950);
    }
  }

  .checkbox-fixed {
    color: var(--frno-blue-300);

    &:hover {
      border-color: var(--frno-blue-500);
    }

    &:checked {
      border-color: var(--frno-blue-500);
    }
  }

  .checkbox-plus {
    color: var(--frno-yellow-200);

    &:hover {
      border-color: var(--frno-yellow-500);
    }

    &:checked {
      border-color: var(--frno-yellow-500);
    }
  }

  .checkbox-other {
    color: var(--frno-cloud-300);

    &:hover {
      border-color: var(--frno-cloud-400);
    }

    &:checked {
      border-color: var(--frno-cloud-400);
    }
  }

  .center-hero .hero-text {
    padding-top: var(--spacing-7);
    padding-bottom: var(--spacing-10);
  }

  .btn-secondary {
    &.tab { 
      background-color: var(--color-button-secondary-hover);

      &:hover {
        background-color: var(--color-button-secondary-active);
      }
    }

    &.is-active {
      color: var(--color-text-primary-inverse);
      background-color: var(--color-button-featured);

      &:hover {
        background-color: var(--color-button-featured-hover);
      }
    }
  }

  .btn-transparent {
    &, &:hover {
      background-color: transparent;
    }
  }

  .underline {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .external-link.underline {
    text-decoration: none;
    
    span {
      text-decoration: underline;
    }

    &:hover span {
      text-decoration: none;
    }
  }
}

.rounded-max {
  border-radius: 50%;
}

.leading-5 {
  line-height: var(--spacing-5);
}

@media screen and (max-width: 1024px) {
  .flex-col-smd {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  #root .self-start-sm {
      align-self: self-start;
    }
}